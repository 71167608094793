import {Component, effect, inject} from '@angular/core'
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser'
import {StartTextComponent} from './start-text/start-text.component'
import {ConfigService} from '../services/config.service'

@Component({
  selector: 'spb-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  standalone: true,
  imports: [StartTextComponent]
})
export class HomeComponent {

  public url: SafeResourceUrl | undefined
  public service = inject(ConfigService)
  protected sanitizer: DomSanitizer = inject(DomSanitizer)

  constructor() {
    effect(() => {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.service.site())
    })
  }

  public getUrl(): SafeResourceUrl {
    return this.url!
  }
}
