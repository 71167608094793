import {ApplicationConfig, importProvidersFrom} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {provideHttpClient} from '@angular/common/http'
import {provideAnimations} from '@angular/platform-browser/animations'
import {provideRouter, withHashLocation} from '@angular/router'
import routes from './app-routes'

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule),
    provideRouter(routes,
      withHashLocation()),
    provideHttpClient(),
    provideAnimations()
  ]

}