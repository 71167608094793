<div class="header">
  <!-- Router link here is default ["/"] -->
  <spb-logo [onlyLogoLink]="true"></spb-logo>
  <div [style.flex-grow]="1"></div>
  <button [matMenuTriggerFor]="menu" mat-button>
    <span class="material-symbols-outlined">menu</span>
  </button>
  <mat-menu #menu="matMenu">
    <button (click)="service.site.set('assets/renewal-test.html')" mat-menu-item>Omsättningar</button>
    <button (click)="service.site.set('assets/sparguide-test.html')" mat-menu-item>Sparguide</button>
  </mat-menu>
</div>