<div class="main">
  <spb-header></spb-header>
  <router-outlet></router-outlet>

  <div [style.flex]="1"></div>
  <spb-footer
    [license]="true"
    [version]="version"
    copyrightYears="2024"
  ></spb-footer>
</div>