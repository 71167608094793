import {Component, inject} from '@angular/core'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu'
import {RouterLinkActive} from '@angular/router'
import {ConfigService} from '../../services/config.service'
import {MatButton} from '@angular/material/button'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [ThemeModule, MatMenu, MatMenuItem, RouterLinkActive, MatMenuTrigger, MatButton]
})
export class HeaderComponent {
  public service = inject(ConfigService)
}
